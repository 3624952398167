import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { NavLink } from "../../atoms/nav/link";
import { ReactComponent as StudioLogo } from "../../../MVVM/Assets/Nav/nav-studio.svg";
import { ReactComponent as MontageLogo } from "../../../MVVM/Assets/Nav/nav-montage.svg";
import { ReactComponent as HistoryLogo } from "../../../MVVM/Assets/Nav/nav-history.svg";
import { HomeIcon, UpgradeIcon } from "../../atoms/icons";
import { URLS } from "../../../MVVM/Utilities/Maps";
import { useExperiment } from "@statsig/react-bindings";

const StyledHomeLogo = styled(HomeIcon)`
  fill: ${(props) => props.theme.colors.chalk};
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    margin-right: 10px;
  }

  ${(props) => {
    return props.isActive && `fill:${props.theme.colors.envy}`;
  }}
`;

const StyledHistoryLogo = styled(HistoryLogo)`
  fill: ${(props) => props.theme.colors.chalk};
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    margin-right: 10px;
  }

  ${(props) => {
    return props.isActive && `fill:${props.theme.colors.envy}`;
  }}
`;

const StyledStudioLogo = styled(StudioLogo)`
  fill: ${(props) => props.theme.colors.chalk};
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    margin-right: 10px;
  }
`;

const StyledMontageLogo = styled(MontageLogo)`
  fill: ${(props) => props.theme.colors.chalk};
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    margin-right: 10px;
  }
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  postion: relative;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 10px;
  min-width: 72px;
  min-height: 40px;
  border-radius: 10px;
  background-color: transparent;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 15px;
  color: ${(props) => props.theme.colors.chalk};

  &.active {
    color: ${(props) => props.theme.colors.chalk};
  }

  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.darkerNed};
  }
  &:hover ${StyledHistoryLogo} {
    fill: ${(props) => props.theme.colors.envy};
  }
  &:hover ${StyledStudioLogo} {
    fill: ${(props) => props.theme.colors.envy};
  }
  &:hover ${StyledMontageLogo} {
    fill: ${(props) => props.theme.colors.envy};
  }
  &:hover ${StyledHomeLogo} {
    fill: ${(props) => props.theme.colors.envy};
  }
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    background-color: transparent;
    min-width: 100%;
    flex-direction: row;
    font-size: 16px;
    min-height: 60px;
    justify-content: flex-start;
  }
`;

export function NavListPrimary(props) {
  const { t } = useTranslation("translation", {
    keyPrefix: "nav.main",
  });
  const name = props.isMobile ? "nav-home-button-mobile" : "nav-home-button";
  const swapTextToMatches = useExperiment("mh-rename-nav-button").get("nav_match_history_button");

  const isHomepageActive = ["clips", ""].includes(
    window.location.pathname.split("/")[1],
  );

  return (
    <>
      <StyledNavLink
        to="/"
        name={name}
        className={isHomepageActive ? "active" : ""}
      >
        <StyledHomeLogo
          mr={[3, 3, 3, 0, 0]}
          size={22}
          isActive={isHomepageActive}
        />
        {t("home")}
      </StyledNavLink>
      <StyledNavLink to={URLS.MATCH_HISTORY}>
        <StyledHistoryLogo
          style={{ height: "30px", width: "30px" }}
          isActive={window.location.pathname.split("/")[1] === "match-history"}
        />
        {swapTextToMatches ? t("matches") : t("history")}
      </StyledNavLink>
      <StyledNavLink name="nav-studio-button" to="/studio">
        <StyledStudioLogo style={{ height: "22px", width: "22px" }} />
        {t("studio")}
      </StyledNavLink>
      <StyledNavLink name="nav-montage-button" to="/montages/create">
        <StyledMontageLogo style={{ height: "22px", width: "22px" }} />
        {t("montages")}
      </StyledNavLink>
      <StyledNavLink to={URLS.UPGRADE} name="nav-upgrade-button">
        <UpgradeIcon mr={[3, 3, 3, 0, 0]} size={22} />
        {t("upgrade")}
      </StyledNavLink>
    </>
  );
}

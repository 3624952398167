import styled from "styled-components";
import { Box, Flex } from "rebass/styled-components";
import Lottie from "lottie-react";
import { RiTrophyLine } from "react-icons/ri";
import { HiOutlineVideoCamera } from "react-icons/hi";
import { Image } from "rebass/styled-components";

import { Body, Headline } from "../../Components/Text";
import { Button } from "../../Components/Button";
import { BiSolidChevronRight } from "react-icons/bi";

export const Column = styled(Flex)`
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

export const CollapsibleColumn = styled(Column)<{ collapsed: boolean }>`
  flex-basis: 40%;
  min-height: 100vh;
  height: auto;
  border-left: 1px solid ${({ theme }) => theme.colors.chalkAlpha20};

  @keyframes matchesSlideIn {
    0% {
      left: 100%;
    }

    50% {
      left: 50%;
    }

    100% {
      left: 0%;
    }
  }

  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    flex-basis: 30%;
  }

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    ${(props) =>
      props.collapsed
        ? "display: none"
        : "animation: matchesSlideIn 0.3s 1; animation-timing-function: linear;"};
  }
`;

export const MatchHistoryContainer = styled(Flex)`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const ViewableContainer = styled(Flex)`
  display: flex;
  max-height: 100vh;
  overflow: hidden;
  width: 100%;
`;
export const StyledCollapsibleColumn = styled(CollapsibleColumn)`
  background: ${({ theme }) => theme.colors.darkerNed};
  min-width: 40%;

  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    height: 100%;
    min-height: 100vh;
    min-width: 100%;
    padding-left: 96px;
    position: absolute;
    width: 100%;
    z-index: 2;

    ${(props) =>
      props.collapsed
        ? "display: none"
        : "animation: matchesSlideIn 0.3s 1; animation-timing-function: linear;"};
  }

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    min-width: 100%;
    padding-left: 0;
    width: 100%;
  }
`;
export const Table = styled.table`
  box-sizing: border-box;
  border-collapse: separate;
  border-spacing: 0px;
  table-layout: fixed;
  width: 100%;
`;

export const Divider = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.colors.chalkAlpha20};
  height: 0px;
  margin: 20px 0;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    margin: 20px 0;
  }
`;

export const TableHead = styled.thead``;
export const TableBody = styled.tbody``;

export const TableRow = styled.tr<{
  isPreview?: boolean;
  noHover?: boolean;
  selected?: boolean;
}>`
  :hover {
    ${(props) =>
      props.selected || props.noHover
        ? ""
        : `background-color: ${props.theme.colors.nedLight}`};
    cursor: pointer;
  }

  ${(props) =>
    props.selected ? `background-color: ${props.theme.colors.darkerNed};` : ""};

  > td {
    border-bottom: 1px solid ${({ theme }) => theme.colors.chalkAlpha20};
  }

  > td:first-child {
    ${(props) =>
      props.isPreview
        ? `box-shadow: -4px 0 0 ${props.theme.colors.envy};`
        : ""};

    ${(props) =>
      props.selected
        ? `border-left: solid 1px ${props.theme.colors.chalkAlpha20};
           padding-left: 9px;`
        : ""};

    @media (max-width: ${({ theme }) => theme.breaks.medium}) {
      border-left: 0;

      ${(props) =>
        props.isPreview
          ? `border-left: solid 4px ${props.theme.colors.envy};
           padding-left: 6px;
           border-radius: none;`
          : ""};
    }
  }
`;

export const TableData = styled.td<{ selected?: boolean }>`
  padding: 10px;
  font-size: ${({ theme }) => theme.text.headline.fontSize};
  font-weight: ${({ theme }) => theme.text.large3.fontWeight};
  position: relative;
  > p {
    margin: 0;
  }

  & .tie {
    color: ${({ theme }) => theme.colors.error};
  }

  & .positive {
    color: ${({ theme }) => theme.colors.envy};
  }

  & .negative {
    color: ${({ theme }) => theme.colors.alert};
  }

  > .date {
    font-size: ${({ theme }) => theme.text.footnote.fontSize};
    color: ${({ theme }) => theme.colors.chalk};
  }
`;

export const ResponsiveLottie = styled(Lottie)`
  height: 16px;
  width: 16px;
  display: inline-block;
  position: relative;
  top: 2px;

  @media (max-width: ${({ theme }) => theme.breaks.narrow}) {
    height: 15px;
    width: 15px;
  }
`;

export const Separator = styled.span`
  display: inline-block;
  padding: 0 8px;

  @media (max-width: ${({ theme }) => theme.breaks.narrow}) {
    padding: 0 2px;
  }

  :before {
    content: "/";
  }
`;

export const Row = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
`;

export const HeaderFlex = styled(Flex)`
  justify-content: space-between;
  gap: 12px;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breaks.narrow}) {
    align-items: flex-start;

    margin-bottom: 10px;
  }
`;

export const Heading = styled.h1`
  all: unset;
  color: #f7fcfd;
  font-family: Barlow;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 66px;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    font-size: 36px;
    margin-bottom: 5px;
  }

  @media (max-width: ${(props) => props.theme.breaks.narrow}) {
    line-height: 36px;
    margin-bottom: 10px;
  }
`;

export const SubText = styled(Headline)`
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: ${(props) => props.theme.colors.chalk};
`;

export const Trophy = styled(RiTrophyLine)`
  color: ${({ theme }) => theme.colors.envy};
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const Video = styled(HiOutlineVideoCamera)`
  color: ${(props) => props.theme.colors.envy};
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const MobileButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.navy};
  border-radius: 20px;
  width: 38px;
  height: 38px;
  padding: 0;
  display: none;
  margin-bottom: 12px;

  :hover {
    background-color: ${({ theme }) => theme.colors.midnight};
  }

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ProcessingButton = styled(Button)`
  border-radius: 999px;
  max-height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.envy};
  background-color: ${(props) => props.theme.colors.envyAlpha10};
  font-size: 16px;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  padding-left: 18px;
  padding-right: 10px;
  &:hover {
    background-color: ${(props) => props.theme.colors.envyAlpha20};
  }
`;
export const MatchesContainer = styled(Box)`
  padding-left: 160px;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    padding: 0 20px;
  }

  @media (max-width: ${(props) => props.theme.breaks.narrow}) {
    padding: 0 10px;
  }
`;
export const MatchRowImageCell = styled(Flex)`
  gap: 30px;

  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    gap: 10px;
  }
`;
export const MatchRowImage = styled(Image)`
  height: 100%;
  width: 100%;
`;
export const MatchRowImageContainer = styled(Box)`
  height: auto;
  max-height: 100px;
  aspect-ratio: 55/100;
  width: auto;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    max-height: 75px;
  }
`;
export const LeagueMatchChampionImage = styled(Image)`
  height: auto;
  max-height: 100px;
  width: auto;
  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    max-height: 50px;
  }
`;
export const StyledScrollableContainer = styled(Flex)`
  -ms-overflow-style: none;
  display: block;
  height: auto;
  overflow-y: scroll;
  position: relative;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const StyledTable = styled(Table)`
  font-size: ${({ theme }) => theme.fontSizes[2]};

  @media (max-width: 1600px) {
    font-size: ${({ theme }) => theme.fontSizes[1]};
  }
`;

export const StyledChevron = styled(BiSolidChevronRight)`
  color: ${({ theme }) => theme.colors.chalk};
`;

export const WarningText = styled(Body)`
  color: ${({ theme }) => theme.colors.error};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: 500;
`;

export const BoldText = styled(Body)`
  font-weight: 600;
`;

export const Date = styled(Body)`
  font-size: ${({ theme }) => theme.fontSizes[0]};
  color: ${({ theme }) => theme.colors.chalk};
`;

export const VictoryText = styled(Body)`
  color: ${({ theme }) => theme.colors.envy};
`;

export const DefeatText = styled(Body)`
  color: ${({ theme }) => theme.colors.alert};
`;
export const TransitTimeWrapper = styled(Box)`
  > div > div {
    margin-bottom: 20px;

    :last-child {
      margin-bottom: 0px;
    }
  }
  border-radius: 16px;
  mix-blend-mode: normal;
  width: 100%;
  border-color: #233438;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 30px #233438;
  background-color: #10161a;
  padding: 20px;
`;

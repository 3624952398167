import { ESupportedGames } from "../../@types";
import { useUser } from "../../Hooks/useUser";

const MatchHistoryViewModel = () => {
  const { allstarUser } = useUser();
  const hasRiotLinked = !!allstarUser?.user?.riot?.puuid;
  const hasSteamLinked = !!allstarUser?.user?.steam?.steamid;
  const hasFaceitLinked = !!allstarUser?.user?.faceit?.nickname;
  const getDefaultGame = () => {
    switch (true) {
      case hasSteamLinked:
        return ESupportedGames.CS2;
      case hasRiotLinked:
        return ESupportedGames.LOL;
      default:
        return ESupportedGames.NONE;
    }
  };

  return { getDefaultGame, hasRiotLinked, hasSteamLinked, hasFaceitLinked };
};

export { MatchHistoryViewModel };

import EsportalIcon from "../../Assets/Platforms/esportal.svg";
import FaceitIcon from "../../Assets/Platforms/faceit.svg";
import GamersclubIcon from "../../Assets/Platforms/gc.svg";
import LeetifyIcon from "../../Assets/Platforms/leetify.svg";
import ScopeIcon from "../../Assets/Platforms/scope.svg";
import SteamIcon from "../../Assets/Platforms/steam.svg";
import { getMapThumbnail } from "../../Utilities/Clip";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

export const maps: MapsType = {
  Agency: `${REACT_APP_S3_CLIP_BUCKET}/static/web/matchHistory/Agency.webp`,
  Ancient: `${REACT_APP_S3_CLIP_BUCKET}/static/web/matchHistory/Ancient.webp`,
  Cache: `${REACT_APP_S3_CLIP_BUCKET}/static/web/matchHistory/Cache.webp`,
  "Dust 2": `${REACT_APP_S3_CLIP_BUCKET}/static/web/matchHistory/DustII.webp`,
  Inferno: `${REACT_APP_S3_CLIP_BUCKET}/static/web/matchHistory/Inferno.webp`,
  Italy: `${REACT_APP_S3_CLIP_BUCKET}/static/web/matchHistory/Italy.webp`,
  Mirage: `${REACT_APP_S3_CLIP_BUCKET}/static/web/matchHistory/Mirage.webp`,
  Nuke: `${REACT_APP_S3_CLIP_BUCKET}/static/web/matchHistory/Nuke.webp`,
  Office: `${REACT_APP_S3_CLIP_BUCKET}/static/web/matchHistory/Office.webp`,
  Overpass: `${REACT_APP_S3_CLIP_BUCKET}/static/web/matchHistory/Overpass.webp`,
  Train: `${REACT_APP_S3_CLIP_BUCKET}/static/web/matchHistory/Train.webp`,
  Tuscan: `${REACT_APP_S3_CLIP_BUCKET}/static/web/matchHistory/Tuscan.webp`,
  Vertigo: `${REACT_APP_S3_CLIP_BUCKET}/static/web/matchHistory/Vertigo.webp`,
  Thera: `${REACT_APP_S3_CLIP_BUCKET}/static/web/matchHistory/Thera.webp`,
  Mills: `${REACT_APP_S3_CLIP_BUCKET}/static/web/matchHistory/Mills.webp`,
  Momento: `${REACT_APP_S3_CLIP_BUCKET}/static/web/matchHistory/Momento.webp`,
  Assembly: `${REACT_APP_S3_CLIP_BUCKET}/static/web/matchHistory/Assembly.webp`,
  Default: `${REACT_APP_S3_CLIP_BUCKET}/static/web/matchHistory/Default.webp`,
};

interface MapsType {
  [key: string]: string;
}

export const platformIcons = (platform: string) => {
  switch (platform.toLowerCase()) {
    case "esportal":
      return EsportalIcon;
    case "faceit":
      return FaceitIcon;
    case "gc":
      return GamersclubIcon;
    case "leetify":
      return LeetifyIcon;
    case "scope":
      return ScopeIcon;
    case "steam":
      return SteamIcon;
    case "valve":
      return SteamIcon;
    default:
      return SteamIcon;
  }
};

export const clipImage = (clip: TClip, mapName: string) => {
  if (clip?.clipImageThumb) {
    return `${REACT_APP_S3_CLIP_BUCKET}/${clip.clipImageThumb}`;
  } else {
    return getMapThumbnail(mapName);
  }
};

export const getOpacityMapThumbnail = (map: string | undefined) => {
  const MAP_URL = `${REACT_APP_S3_CLIP_BUCKET}/static/matchHistory/matchPreviewMaps`;

  switch (map) {
    case "Dust 2":
      return `${MAP_URL}/dust_1.webp`;
    case "Mirage":
      return `${MAP_URL}/mirage_1.webp`;
    case "Inferno":
      return `${MAP_URL}/inferno_1.webp`;
    case "Overpass":
      return `${MAP_URL}/overpass_1.webp`;
    case "Nuke":
      return `${MAP_URL}/nuke_1.webp`;
    case "Train":
      return `${MAP_URL}/train_1.webp`;
    case "Vertigo":
      return `${MAP_URL}/vertigo_1.webp`;
    case "Ancient":
      return `${MAP_URL}/ancient_1.webp`;
    case "Anubis":
      return `${MAP_URL}/anubis_1.webp`;
    case "Cache":
      return `${MAP_URL}/cache_1.webp`;
    case "Agency":
      return `${MAP_URL}/agency_1.webp`;
    case "Office":
      return `${MAP_URL}/office_1.webp`;
    case "Tuscan":
      return `${MAP_URL}/tuscan_1.webp`;
    default:
      return `${MAP_URL}/dust_1.webp`;
  }
};

export const getWeaponSvg = (weapon: string) => {
  const weaponMap: { [key: string]: string } = {
    "Galil AR": "galilar.svg",
    "USP-S": "usp_silencer.svg",
    "HE Grenade": "hegrenade.svg",
    P90: "p90.svg",
    "R8 Revolver": "revolver.svg",
    "Incendiary Grenade": "incgrenade.svg",
    "CZ75 Auto": "cz75a.svg",
    G3SG1: "g3sg1.svg",
    "SCAR-20": "scar20.svg",
    "Smoke Grenade": "smokegrenade.svg",
    M249: "m249.svg",
    "MP5-SD": "mp5sd.svg",
    "PP-Bizon": "bizon.svg",
    "MAC-10": "mac10.svg",
    "Tec-9": "tec9.svg",
    "SSG 08": "ssg08.svg",
    Nova: "nova.svg",
    "MAG-7": "mag7.svg",
    "SG 553": "sg556.svg",
    AUG: "aug.svg",
    FAMAS: "famas.svg",
    P2000: "p2000.svg",
    P250: "p250.svg",
    Knife: "knife.svg",
    "Zeus x27": "taser.svg",
    "UMP-45": "ump45.svg",
    "Glock-18": "glock.svg",
    "Dual Berettas": "elite.svg",
    M4A4: "m4a1.svg",
    M4A1: "m4a1_silencer.svg",
    AWP: "awp.svg",
    MP7: "mp7.svg",
    "AK-47": "ak47.svg",
    Negev: "negev.svg",
    MP9: "mp9.svg",
    "Five-SeveN": "fiveseven.svg",
    XM1014: "xm1014.svg",
    "Sawed-Off": "sawedoff.svg",
    "Desert Eagle": "deagle.svg",
    World: "icon_suicide.svg",
  };

  return weaponMap[weapon];
};

import { useState, useEffect } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import axios from "axios";
import { useUser } from "../../Hooks/useUser";
import {
  RequestedClips,
  RequestedMontages,
  TClipsRequested,
  TMontageRequest,
} from "../../State/matchHistory";
import {
  CHECK_FOR_CLIP,
  GET_MATCH,
  MATCH_HISTORY,
  REFRESH_FACEIT,
  ROLL_SHARE_CODES,
  STEAM_FRIENDS,
} from "../../GraphQL/matchHistory";
import { GET_MY_SIGNATURE } from "../../GraphQL/loadoutSelector";
import { getQueryParam } from "../../Utilities/QueryParam";
import { MatchHistoryViewModel } from "../MatchHistory/ViewModel";
import { useSetRecoilState } from "recoil";
import { TMatchHistory } from "./@types";

export const CS2MatchHistoryViewModel = () => {
  const [endOfList, setEndOfList] = useState(false);
  const [noMatches, setNoMatches] = useState(false);
  const page = getQueryParam("page") || "1";
  const limit = 10;
  const { hasSteamLinked } = MatchHistoryViewModel();
  const { allstarUser } = useUser();
  const setRequestedMontages = useSetRecoilState(RequestedMontages);
  const setRequestedClips = useSetRecoilState(RequestedClips);
  const {
    data: matchesData,
    refetch: refetchMatches,
    error: matchesError,
    loading: matchesLoading,
  } = useQuery(MATCH_HISTORY, {
    variables: {
      limit,
      page: parseInt(page),
      steamId: allstarUser?.user?.steam?.steamid,
    },
    skip: !hasSteamLinked,
    fetchPolicy: "cache-and-network",
  });
  const {
    data: friendsData,

    error: friendsError,
    loading: friendsLoading,
  } = useQuery(STEAM_FRIENDS, {
    skip: !hasSteamLinked,
    fetchPolicy: "cache-and-network",
  });

  const {
    data: signatureData,
    error: signatureError,
    loading: signatureLoading,
  } = useQuery(GET_MY_SIGNATURE, {
    fetchPolicy: "cache-and-network",
  });

  const [getClip] = useLazyQuery(CHECK_FOR_CLIP, {
    fetchPolicy: "network-only",
  });

  const [rollShareCodes, { loading: rollShareCodesLoading }] = useLazyQuery(
    ROLL_SHARE_CODES,
    { fetchPolicy: "network-only" },
  );

  const [refreshFaceit, { loading: refreshFaceitLoading }] = useMutation(
    REFRESH_FACEIT,
    { fetchPolicy: "network-only" },
  );
  const [getMatch, { loading: getMatchLoading }] = useLazyQuery(GET_MATCH, {
    fetchPolicy: "network-only",
  });

  const [noSteamError, setNoSteamError] = useState(false);

  useEffect(() => {
    if (!matchesData) return;
    const { moments: matches } = matchesData.matchHistory;
    if (!matches.length) return;

    const processingMontages = [] as TMontageRequest[];
    const processingClips = [] as TClipsRequested[];
    for (const match of matches) {
      for (const montage of match.matchMontages) {
        const clip = montage?.clip;
        if (["Queued", "Submitted"].includes(clip?.status)) {
          processingMontages.push({
            matchId: match.csgoMatchId,
            ts: Date.now(),
            type: montage.type,
            steamIds: [],
            trackId: clip?.requestId,
          });
        }
      }

      for (const moment of match.matchMoments) {
        const clip = moment?.clip;
        if (["Queued", "Submitted"].includes(clip?.status)) {
          processingClips.push({
            matchId: match.csgoMatchId,
            ts: Date.now(),
            round: moment.round,
            steamId: "",
          });
        }
      }
    }
    setRequestedClips(processingClips);
    setRequestedMontages(processingMontages);
  }, [matchesData, setRequestedMontages, setRequestedClips]);
  useEffect(() => {
    if (!allstarUser?.user?.steam?.steamid) {
      return setNoSteamError(true);
    }
  }, [allstarUser]);

  const requestClip = async ({
    matchId,
    round,
  }: {
    matchId: string;
    round: number;
  }) => {
    const steamId = allstarUser?.user.steam?.steamid || "";
    const clipReq = `${process.env.REACT_APP_AWS_SERVERLESS}/webhook/clip-request`;
    const clipsRequestedState = {
      matchId,
      round,
      steamId,
      ts: Date.now(),
    };

    try {
      await axios.post(`${clipReq}?token=${localStorage.getItem("token")}`, {
        matchId,
        steamId: steamId,
        round,
      });
    } catch (error) {
      return error;
    }
    setRequestedClips((prev) => {
      return [...prev, clipsRequestedState];
    });
    return;
  };

  const setRequestedMontagesState = async (request: TMontageRequest) => {
    setRequestedMontages((prev) => {
      return [...prev, request];
    });
  };

  return {
    endOfList,
    fetch,
    getClip,
    getMatch,
    getMatchLoading,
    matchesData,
    friendsData,
    signatureData,
    matchesLoading,
    matchesError,
    friendsLoading,
    noMatches,
    noSteamError,
    page,
    refreshFaceit,
    refreshFaceitLoading,
    requestClip,
    setRequestedMontagesState,
    rollShareCodes,
    rollShareCodesLoading,
    limit,
  };
};

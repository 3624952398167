import React, { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { StatsigProvider } from "@statsig/react-bindings";
import { StatsigClient } from "@statsig/js-client";

import "./index.css";
import { initialize } from "./MVVM/Utilities/statsigClient";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./i18n.js";

if (module.hot) {
  module.hot.accept();
}

function RootComponent() {
  const [client, setClient] = useState<StatsigClient | undefined>();

  useEffect(() => {
    initialize().then(setClient);
  }, [setClient]);

  if (!client) return <></>;

  return (
    <StatsigProvider client={client} loadingComponent={<></>}>
      <Suspense fallback={<></>}>
        <App />
      </Suspense>
    </StatsigProvider>
  );
}

ReactDOM.render(
  <RootComponent />,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import axios from "axios";
import { formatDistance } from "date-fns";

import { postApiSegment } from "../../Analytics";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

export const dateFormatWithDistanceSuffix = (createdDate: string) => {
  try {
    const date = /^\d+$/.test(createdDate)
      ? parseInt(createdDate)
      : createdDate;
    return formatDistance(new Date(date), new Date(), {
      addSuffix: true,
    });
  } catch (_) {
    return "";
  }
};

export function getClipTitle(clip: any) {
  if (!clip) return;
  if (clip.userClipTitle) {
    return `${clip.userClipTitle}`;
  }
  if (clip.clipTitle && clip.clipTitle !== "") {
    return `${clip.clipTitle}`;
  } else {
    return `${clip.clipTitleSuffix}`;
  }
}

export const CLIP_STATUS = {
  DELETED: "Deleted",
  PROCESSED: "Processed",
  INACTIVE: "Inactive",
  SUBMITTED: "Submitted",
};

export function formatNumber(num: any, lowerBoundary = 10000) {
  if (!num) {
    return 0;
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(2).toLocaleString() + "M";
  }

  if (num >= lowerBoundary) {
    return (num / 1000).toFixed(1).toLocaleString() + "K";
  }

  return num.toLocaleString();
}

export const clipHasStudio = (clip: any) =>
  clip?.personalizations?.cards?.length > 0 || clip?.studio?.length > 0;

export function getDisplayName(clip: any) {
  const displayName = clip.username || clip.steamHandle;
  return displayName || "Allstar User";
}

export const canRemix = (clip: any) => {
  if (clip?.partner?.useCase === "SH" || clip?.partner?.useCase === "MH")
    return false;
  if (![101, 407].includes(clip.game) && !clip.isMontage) return true;

  return clip.isRemixable;
};

export const trackClipDownload = (clip: any, aspectRatio: any, extra: any) => {
  window.rudderanalytics.track(
    `${aspectRatio ? "Mobile Clip Download" : "Clip Download"}`,
    {
      game: clip.game,
      tags: clip.clipProperties,
      length: clip.clipLength,
      shareId: clip.shareId,
      ...extra,
    },
  );

  postApiSegment({
    event: "Creator - Clip Downloaded",
    userId: clip.user?._id,
    properties: {
      clipName: clip.clipTitle,
      game: clip.game,
      tags: clip.clipProperties,
      length: clip.clipLength,
      total_reactions: clip.totalReactions,
      total_views: clip.views,
      shareId: clip.shareId,
      aspectRatio,
      ...extra,
    },
  });
};

export const countClipImpression = (clip: TClip) => {
  axios
    .post(`${process.env.REACT_APP_AWS_SERVERLESS}/clips/views`, {
      shareId: clip.shareId,
      isClip: true,
    })
    .catch(() => {});
};

const thumbnailMaps = [
  "Dust 2",
  "Mirage",
  "Inferno",
  "Overpass",
  "Nuke",
  "Train",
  "Vertigo",
  "Ancient",
  "Anubis",
  "Cobble",
  "Cache",
  "Agency",
  "Office",
  "Tuscan",
  "Assembly",
  "Mills",
  "Momento",
  "Thera",
];

export const getMapThumbnail = (
  map: string | undefined,
  randomize: boolean = false,
) => {
  const mapNumber = randomize ? Math.round(Math.random()) + 1 : 1;
  const THUMBNAIL_URL = `${REACT_APP_S3_CLIP_BUCKET}/static/clip-map-thumbs`;

  if (map && thumbnailMaps.includes(map)) {
    const mapName = map?.replace(/[\s\d]+/, "").toLowerCase();

    return `${THUMBNAIL_URL}/${mapName}_${mapNumber}.webp`;
  }

  return `${THUMBNAIL_URL}/dust_${mapNumber}.webp`;
};

import React from "react";

import { ESupportedGames, EGameSelectorHash } from "../../@types";
import { ILeagueMatch, ILeagueMatchMoment } from "../MatchHistoryLOL/@types";
import { TMatchHistory, IMatchMoment } from "../MatchHistoryCS2/@types";
import { IAllstarUser } from "../Setup/Components/@types";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

type TMatch = ILeagueMatch | TMatchHistory;
type TMoment = ILeagueMatchMoment | IMatchMoment;

export const changeGame = (game: ESupportedGames) => {
  switch (game) {
    case ESupportedGames.CS2:
      return (window.location.hash = EGameSelectorHash.CS2);
    case ESupportedGames.LOL:
      return (window.location.hash = EGameSelectorHash.LOL);
    default:
      return (window.location.hash = EGameSelectorHash.CS2);
  }
};

export const formatDate = (date: string) =>
  new Date(parseInt(date)).toLocaleString("en-us", {
    month: "short",
    day: "numeric",
  });

export const formatTime = (time: number) => {
  const date = new Date(time * 1000).toISOString();
  return time > 3600 ? date.substring(11, 19) : date.substring(14, 19);
};

export const processingClips = (match: TMatch) =>
  match.matchMoments.filter(
    (moment: TMoment) =>
      moment.hasClip && ["Queued", "Submitted"].includes(moment.clip.status),
  ).length;

export const MomentsTotal = ({ match }: { match: TMatch }) => {
  if (match.status === "Preview") return <span>-</span>;

  return <span>{match.matchMoments.length}</span>;
};

export const MomentsProcessed = ({ match }: { match: TMatch }) => {
  if (match.status === "Preview") return <span>-</span>;
  return (
    <span className="positive">
      {
        match.matchMoments.filter((moment: TMoment) => !!moment?.clip?._id)
          .length
      }
    </span>
  );
};

export const gameOutcome = (win: boolean) => (win ? "Victory" : "Defeat");
export const csGameOutcome = (win: boolean) => (win ? "Win" : "Loss");

export const formatGameMode = (gameMode: string) =>
  `${gameMode.charAt(0)}${gameMode.slice(1).toLowerCase()}`;

export const formatDuration = (seconds: number) =>
  `${~~(seconds / 60)}:${seconds % 60}`;

export const getCs2OpponentTeamIcon = (ourUsersTeam: string) => {
  const opponentsTeam = ourUsersTeam === "T" ? "CT" : "T";
  return opponentsTeam === "CT"
    ? `${REACT_APP_S3_CLIP_BUCKET}/static/web/cs2_icons/team/ct_logo.svg`
    : `${REACT_APP_S3_CLIP_BUCKET}/static/web/cs2_icons/team/t_logo.svg`;
};

export const mustLinkAnAccount = ({
  game,
  allstarUser,
}: {
  game: ESupportedGames;
  allstarUser: IAllstarUser;
}) => {
  switch (game) {
    case ESupportedGames.LOL:
      return !allstarUser?.user?.riot?.active;
    case ESupportedGames.CS2:
      return (
        !allstarUser?.user?.steam?.active &&
        !allstarUser?.user?.faceit?.nickname
      );
    case ESupportedGames.NONE:
      return true;

    default:
      return null;
  }
};

import { StatsigClient } from "@statsig/js-client";

import { User } from "../../Model/User";

let client: StatsigClient | undefined;

const { NODE_ENV, REACT_APP_STATSIG_ENV, REACT_APP_STATSIG_SDK_KEY } = process.env;

export async function initialize() {
  const decoded = await User.getInstance().DecodeToken();
  client = new StatsigClient(
    REACT_APP_STATSIG_SDK_KEY || "",
    { email: decoded?.sub, userID: decoded?._id },
    { environment: { tier: REACT_APP_STATSIG_ENV || NODE_ENV || "development" } },
  );

  await client.initializeAsync();
  return client;
}

export default client;
